import { ThemeProvider } from "@emotion/react";
import { CssBaseline, createTheme } from "@mui/material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";


import { HomePage } from './homepage';
import { Work } from './work';
import { WhatWeDo } from './whatwedo';
import { Clients } from './clients';
import { ContactUs } from './contactus';
import { Results } from './results';
// import { Strategy } from './strategy';
import { Approach } from './approach';
import { Team } from './team';
import { useContentData } from "./constants";

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';


import { NavBarLeft } from "./navbarleft";

import "./horizontalscroll.css";

const darkTheme = (useDark) =>
  createTheme({
    typography: {
      // fontFamily: "Open Sans Hebrew, sans-serif",
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => (ownerState.customsubmit ? {
            fontSize: "18px",
            fontFamily: '"futura-pt",sans-serif',
            fontWeight: "bold",
            textTransform: "none",
            color: useDark ? "white" : 'unset',
          } : (ownerState.defaultbutton ? {
            color: useDark ? 'white' : 'black',
          } : {
            // backgroundColor: 'rgb(245 158 11)',
            textTransform: "none",
            color: 'rgb(245 158 11)',
            fontSize: "1.2rem",
          })
          ),
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: "18px",
            fontFamily: '"futura-pt",sans-serif',
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: ({ ownerState }) => ({

            fontSize: "18px",
            fontFamily: '"futura-pt",sans-serif',
          })
        }
      },

      MuiInputLabel: {
        styleOverrides: {
          root: ({ ownerState }) => {

            return {
              color: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
              fontSize: "18px",
              fontFamily: '"futura-pt",sans-serif',
              borderColor: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
              "&.Mui-focused": {
                color: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
                left: 0,
                top: 0,
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: 'white'
        },
        root: ({ ownerState }) => {
          return {

            borderColor: 'white!important'

          }
        },
      },
      MuiTextField: {
        styleOverrides: {

          // root: ({ ownerState }) => ({
          //   color: (useDark || ownerState.forcewhite) ? 'white' : 'black'
          // })
          // root: ({ ownerState }) => {
          //   // console.log(ownerState.label, (useDark || (ownerState.darkoverlay)) ? "white" : 'black');
          //   // if (ownerState.name === 'your-email-overlay') {
          //   //   console.log(ownerState.name, (useDark || (ownerState.darkoverlay)) ? "white" : 'black');

          //   // }
          //   return { color: (useDark || (ownerState.darkoverlay)) ? "white" : 'black' }
          // }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ ownerState }) => {
            return {
              fontSize: "18px",
              fontFamily: '"futura-pt",sans-serif',
              borderColor: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
              color: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
              "&.Mui-focused": {
                color: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
                left: 0,
                top: 0,
              },
              "&.Mui-focused:after": {
                borderColor: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
              },
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: ({ ownerState }) => {
            // console.log(ownerState.name);
            // if (ownerState.name === 'your-email-overlay') {
            //   console.log(ownerState);
            //   // console.log(ownerState.name, (useDark || (ownerState.darkoverlay)) ? "white" : 'black');

            // }
            // console.log(ownerState);
            return {
              fontSize: "18px",
              fontFamily: '"futura-pt",sans-serif',
              borderColor: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
              color: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
              "&.Mui-focused": {
                color: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
                left: 0,
                top: 0,
              },
              "&.Mui-focused:after": {
                borderColor: (useDark || (ownerState.darkoverlay)) ? "white" : 'black',
              },
            }

          },
        },
        // styleOverrides: {
        //   root:
        //     variant === "outlined"
        //       ? {
        //           fontSize: "1.5rem",
        //         }
        //       : {
        //           "&::before": {
        //             borderBottom: "0px ",
        //           },
        //           "&:hover:not(.Mui-disabled, .Mui-error):before": {
        //             borderBottom: "0px ",
        //           },
        //           "&.Mui-focused:after": {
        //             borderBottom: "0px ",
        //           },
        //         },
        // },
      },
      MuiIconButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: ownerState.forcewhite ? 'white' : (useDark ? 'white' : 'black')
          }),
        },
      },
    },
    palette: {
      mode: useDark ? "dark" : "light",
      background: {
        default: useDark ? "#17202C" : "#f5f5f5",
      },
    },
  });

function choose(choices) {
  var index = Math.floor(Math.random() * choices.length);
  return choices[index];
}

export const HorizontalScroll = () => {
  const [formMessage, setFormMessage] = useState(null);
  const [theme, setTheme] = useState(choose(['dark', 'monument', 'cherry']));
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageI, setCurrentPageI] = useState(0);
  const [subtextHidden, setSubtextHidden] = useState([0, 1, 2, 3, 4, 5, 6, 7]);
  const [globalSw, setGlobalSw] = useState(0);
  const contentData = useContentData('index.php/wp-json/wp/v2/posts/397')


  useEffect(() => {
    // console.log(currentPage);

    const wideRef = document.getElementById("widediv");
    wideRef.style.transform = `translate(-${currentPage * 100}vw)`;

    if (window.innerWidth > 1024) {
      setTimeout(() => {
        if (subtextHidden.includes(currentPage)) {
          subtextHidden.splice(subtextHidden.indexOf(currentPage), 1);
          setSubtextHidden(subtextHidden.slice())
        }
      }, 750);
    }


  }, [currentPage]);

  console.log(contentData)


  const headerLinkClick = (i) => {

    // let pageDest = i;
    // if (i == 2.5) {
    //   setCurrentPageI(1);
    //   pageDest = 2;
    // } else {
    //   setCurrentPageI(0);
    // }
    const wideRef = document.getElementById("widediv");
    wideRef.style.transition = `transform 1s`;
    setTimeout(() => {
      wideRef.style.transition = ``;
    }, 1000)
    if (i == 2.5) {
      setCurrentPage(2);
      setCurrentPageI(1);
    } else {
      setCurrentPage(i);
      if (!(currentPage == 2 && i == 3)) {
        setCurrentPageI(0);
      }

    }



    // setShowSubtext(false);
    // setTimeout(() => {
    //   setShowSubtext(true);
    // }, 500)


  };


  useEffect(() => {
    setGlobalSw(window.innerWidth);
    headerLinkClick(0);
    window.onresize = () => {
      setGlobalSw(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    if (globalSw < 1024) {
      setCurrentPage(0);
      setSubtextHidden([])
    }
  }, [globalSw])


  // console.log("render");
  // const globalSw = window.innerWidth;
  const showNav = globalSw > 1024 && (currentPage != 0);

  return (
    <ThemeProvider theme={darkTheme(theme == 'dark')}>
      <CssBaseline />
      <div className="h-screen w-screen flex lg:overflow-y-hidden overflow-x-hidden"
      >


        <div
          className={"tk-futura-pt flex flex-col lg:block lg:w-screen" + (theme == 'dark' ? ' dark ' : '') + (theme == 'monument' ? ' bg-neutral-200 ' : '') + (theme == 'cherry' ? ' bg-amber-100 ' : '')}

          id="talldiv"

        >
          {showNav ? <NavBarLeft headerLinkClick={headerLinkClick} theme={theme} /> : <></>}
          <div className="  flex flex-col lg:flex-row top-0 left-0 h-screen lg:sticky lg:overflow-hidden z-10  text-black dark:text-white  " id="widediv" >
            {/* {(currentPage > 0 && currentPage < 6) ? <NavBarLeft headerLinkClick={headerLinkClick} theme={theme} /> : <></>} */}


            <HomePage theme={theme} contentData={contentData} showSubtext={!subtextHidden.includes(0)} setTheme={setTheme} headerLinkClick={headerLinkClick} globalSw={globalSw} />

            <WhatWeDo theme={theme} contentData={contentData} showSubtext={!subtextHidden.includes(1)} headerLinkClick={headerLinkClick} globalSw={globalSw} />

            <Work theme={theme} contentData={contentData} showSubtext={!subtextHidden.includes(2)} headerLinkClick={headerLinkClick} pageI={currentPageI} setCurrentPageI={setCurrentPageI} globalSw={globalSw} />


            <Approach theme={theme} contentData={contentData} showSubtext={!subtextHidden.includes(3)} headerLinkClick={headerLinkClick} globalSw={globalSw} />

            <Results theme={theme} contentData={contentData} showSubtext={!subtextHidden.includes(4)} headerLinkClick={headerLinkClick} globalSw={globalSw} />

            <Clients theme={theme} contentData={contentData} showSubtext={!subtextHidden.includes(5)} headerLinkClick={headerLinkClick} globalSw={globalSw} />


            <Team theme={theme} contentData={contentData} showSubtext={!subtextHidden.includes(6)} headerLinkClick={headerLinkClick} globalSw={globalSw} />

            <ContactUs theme={theme} contentData={contentData} showSubtext={!subtextHidden.includes(7)} setTheme={setTheme} headerLinkClick={headerLinkClick} globalSw={globalSw} />


          </div>

        </div>
        {/* <div className="fixed bottom-4 hidden lg:block z-50 w-full  pl-24 pr-8">
        <LinearProgress variant="determinate" value={perc} />

      </div> */}
      </div>
    </ThemeProvider>
  );
};
