import { Button, TextField } from '@mui/material';
import { Header } from './header';
import { NavBarLeft } from './navbarleft';
import { getBg, urlBase, getBgAttachment, getBgSize } from './constants';
import { useState } from 'react';

export const ContactUs = ({ theme, showSubtext, setTheme, contentData, headerLinkClick, globalSw }) => {

    const [formMessage, setFormMessage] = useState('')


    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        // formData.append("_wpcf7_unit_tag", "wpcf7-f3671-p3671-o1");
        // formData.append("_wpcf7", "3671");
        fetch("/wp-content/themes/hello-elementor/sidebar.php", {
            method: "POST",
            body: formData,
        }).then((response) => {
            setFormMessage('Message received');
            setTimeout(() => {
                setFormMessage("");
            }, 20_000);
        }).catch((err) => {
            setFormMessage('Error sending form')
        });
    };
    return (

        <div
            id="page8"
            style={{ backgroundImage: getBg(7, theme, globalSw), backgroundSize: getBgSize(7, theme, globalSw), backgroundRepeat: 'no-repeat', backgroundAttachment: getBgAttachment(7, theme, globalSw) }}
            className=" flex-col z-10  flex  h-auto lg:h-screen w-screen  pt-20 lg:py-0  "
        >
            <div className="flex flex-col max-w-screen-2xl lg:my-auto  pb-20 lg:pb-10 px-8 lg:ml-12  h-full">
                {/* <div className=" w-full hidden h-auto  my-8 lg:mb-0 lg:grid grid-cols-2">
                    <div

                        className=" hidden lg:flex cursor-pointer"
                        onClick={() => headerLinkClick(0)}
                    >
                        <img className="h-auto lg:h-12" src={theme === "dark" ? `${urlBase.replace('/06', '/07')}Daschle-WhiteLogo.png` : `${urlBase.replace('/06', '/07')}Daschle-ColorLogo.png`} />
                    </div>
                    <div className="hidden lg:block">
                        <Header setTheme={setTheme} headerLinkClick={headerLinkClick} />
                    </div>
                </div> */}
                <div className='flex flex-col my-auto '>


                    <div className="flex flex-col lg:grid grid-cols-2 w-full h-auto mt-auto">
                        <div className="flex flex-col max-w-lg mx-auto">
                            <div className=" text-4xl lg:text-5xl mb-4">
                                {contentData.contact_title}
                            </div>
                            {showSubtext ?
                                <>
                                    <div className="text-custom1 lg:font-18 max-w-md slidein">
                                        {contentData.contact_1}
                                    </div>
                                    <div className="flex flex-col text-custom1 lg:text-custom1 mt-8 slidein">
                                        <div dangerouslySetInnerHTML={{ __html: contentData.contact_address?.replaceAll('\r\n', '<br/>') ?? '' }}></div>
                                        {/* <div className="">901 K St. NW, Suite 900</div>
                                        <div className="">Washington, D.C. 20001</div>
                                        <div className="">(202) 508-3400</div> */}
                                    </div>
                                    <div className="flex text-custom1 lg:font-18 flex-col mt-8 lg:mt-4 mb-8 lg:mb-0 slidein">
                                        <a target="_blank" href="https://x.com/daschlegroup">Twitter</a>
                                        <a target="_blank" href="https://www.linkedin.com/company/the-daschle-group">LinkedIn</a>
                                    </div>

                                </> :
                                <div className=' opacity-0 '>
                                    <div className="text-custom1 lg:font-18 max-w-md ">
                                        We look forward to hearing from you and discussing how we
                                        can support your advocacy and strategic advisory needs.
                                    </div>
                                    <div className="flex flex-col text-custom1 lg:text-custom1 mt-8 ">
                                        <div className="">900 K St. NW, Suite 900</div>
                                        <div className="">Washington, D.C.</div>
                                        <div className="">20001</div>
                                        <div className="">(202) 508-3400</div>
                                    </div>
                                    <div className="flex text-custom1 lg:font-18 flex-col mt-8 lg:mt-4 mb-8 lg:mb-0 ">
                                        <a target="_blank" href="https://x.com/daschlegroup">Twitter</a>
                                        <a target="_blank" href="https://www.linkedin.com/company/the-daschle-group">LinkedIn</a>
                                    </div>
                                    {/* <a href="" className=" hidden lg:flex items-center text-2xl cursor-pointer hover:text-amber-400 ">
                                        <div className='font-18'>Internships at TDG</div>
                                    </a> */}
                                </div>
                            }
                        </div>

                        <div className="flex flex-col">
                            <div className="text-2xl">Let's Chat</div>

                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col lg:grid grid-cols-2 gap-y-4 w-full h-auto gap-x-2">
                                    <TextField
                                        className="col-span-1"
                                        label="First Name"
                                        variant="standard"
                                        name="your-first"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Last Name"
                                        variant="standard"
                                        className="col-span-1"
                                        name="your-first"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Email Address"
                                        className="col-span-1"
                                        variant="standard"
                                        name="your-email"
                                        fullWidth
                                        margin="normal"
                                    />

                                    <TextField
                                        label="Subject"
                                        className="col-span-1"
                                        variant="standard"
                                        name="your-subject"
                                        fullWidth
                                        margin="normal"
                                    />

                                    <TextField
                                        label="Message"
                                        variant="standard"
                                        className="col-span-2"
                                        placeholder="What Can We Help With?"
                                        name="your-message"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        margin="normal"
                                    />

                                    <div className='col-span-1 w-fit'>
                                        <Button
                                            customsubmit={1}
                                            variant="text"

                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </div>

                                </div>
                            </form>
                            <div className="">{formMessage}</div>
                        </div>
                    </div>
                    <div className="flex ">

                        <div className="ml-auto">
                            All Rights Reserved. Copyright 2024 Daschle Group
                        </div>
                    </div>
                </div>
            </div>


            <div className="w-screen hidden lg:flex lg:mb-10 items-end fixed bottom-0 z-10">

                <div className="hidden lg:flex items-center text-2xl  ml-auto mr-14 cursor-pointer hover:text-amber-400">
                    <div onClick={() => headerLinkClick(6)} className="text-custom2 relative bottom-0   hover:font-bold">&#10229;</div>
                </div>

            </div>
        </div>
    )
}