
import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ContrastIcon from "@mui/icons-material/Contrast";

export const Header = ({ headerLinkClick, setTheme }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (i) => {
        setAnchorEl(null);
        console.log({ i });
        i && headerLinkClick(i);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = (t) => {
        setAnchorEl2(null);
        t && setTheme(t);
    };

    return (
        <>
            <div className=" lg:hidden">
                <IconButton
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                >
                    <MenuIcon fontSize="large" />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose(null)}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <MenuItem onClick={() => handleClose(1)}>What We Do</MenuItem>
                    <MenuItem onClick={() => handleClose(2)}>Work</MenuItem>
                    <MenuItem onClick={() => handleClose(2.5)}>Strategy</MenuItem>
                    <MenuItem onClick={() => handleClose(3)}>Approach</MenuItem>
                    <MenuItem onClick={() => handleClose(4)}>Results</MenuItem>
                    <MenuItem onClick={() => handleClose(5)}>Clients</MenuItem>
                    <MenuItem onClick={() => handleClose(6)}>Team</MenuItem>
                    <MenuItem onClick={() => handleClose(7)}>Contact Us</MenuItem>
                </Menu>
            </div>
            <div className=" hidden lg:flex flex-wrap lg:text-custom1 justify-between  min-w-600  max-w-3xl items-center  ">
                <div
                    className=" cursor-pointer hover:text-amber-400 mx-2"
                    onClick={() => headerLinkClick(1)}
                >
                    What We Do
                </div>
                <div className="cursor-pointer hover:text-amber-400 mx-2" onClick={() => headerLinkClick(2)}>
                    Work
                </div>
                <div className="cursor-pointer hover:text-amber-400 mx-2" onClick={() => headerLinkClick(2.5)}>
                    Strategy
                </div>
                <div
                    className="cursor-pointer  hover:text-amber-400 mx-2"
                    onClick={() => headerLinkClick(3)}
                >
                    Approach
                </div>
                <div className="cursor-pointer hover:text-amber-400 mx-2" onClick={() => headerLinkClick(4)}>
                    Results
                </div>
                <div className="cursor-pointer hover:text-amber-400 mx-2" onClick={() => headerLinkClick(5)}>
                    Clients
                </div>
                <div className="cursor-pointer hover:text-amber-400 mx-2" onClick={() => headerLinkClick(6)}>
                    Team
                </div>
                <div className="cursor-pointer hover:text-amber-400 mx-2" onClick={() => headerLinkClick(7)}>
                    Contact Us
                </div>

                {/* <IconButton
                    id="basic-button"
                    aria-haspopup="true"
                    onClick={() => setTheme(theme === 'dark' ? 'monument' : 'dark')}
                >
                    <ContrastIcon fontSize="large" />
                </IconButton> */}

                <IconButton
                    id="basic-button"
                    className="scale-75"
                    aria-controls={open2 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleClick2}
                >
                    <ContrastIcon fontSize="large" />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl2}
                    open={open2}
                    onClose={() => handleClose2(null)}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <MenuItem onClick={() => handleClose2('dark')}>Dark</MenuItem>
                    <MenuItem onClick={() => handleClose2('monument')}>Monument</MenuItem>
                    <MenuItem onClick={() => handleClose2('cherry')}>Cherry</MenuItem>
                </Menu>
            </div>
        </>
    );
};