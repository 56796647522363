
import { getBg, getBgAttachment, getBgSize } from "./constants"
import { NavBarLeft } from "./navbarleft"
export const Results = ({ theme, showSubtext, contentData, globalSw, headerLinkClick }) => {
    return (
        <div
            id="page5"
            style={{
                backgroundImage: getBg(4, theme, globalSw),
                backgroundSize: getBgSize(4, theme, globalSw), backgroundAttachment: getBgAttachment(4, theme, globalSw)
            }
            }
            className=" flex-col z-30    flex lg:h-screen w-screen  py-20 lg:py-0  "
        >
            <div className="flex flex-col max-w-screen-2xl lg:my-32 lg:mb-0 lg:mt-8  px-8  lg:h-full">
                <div className="lg:h-full mx-auto flex flex-col overflow-hidden lg:my-auto max-w-xl   ">
                    <div className="text-3xl center-text-6xl lg:text-5xl xl:text-6xl mb-4">
                        {contentData.results_title}
                    </div>
                    {showSubtext ?
                        <>
                            <div className="slidein text-custom1 lg:font-18 mb-3">
                                {contentData.results_1}
                            </div>
                            <div className="slidein text-custom1 lg:font-18 mb-3">
                                {contentData.results_2}
                            </div>
                            <div className="slidein text-custom1 lg:font-18 mb-3">
                                {contentData.results_3}
                            </div>
                        </> :
                        <div className=" opacity-0">
                            <div className=" text-custom1 lg:font-18 mb-3">
                                Our mantra is “live above the shop.” We are the ones responsible for turning off the lights and locking the door each night.
                            </div>
                            <div className=" text-custom1 lg:font-18 mb-3">
                                This means that we assume your goals, concerns, and priorities as our own. We read the news as if we were you, and we call you before you call us.
                            </div>
                            <div className=" text-custom1 lg:font-18 mb-3">
                                We show up. We take ownership. And we deliver.
                            </div>
                        </div>
                    }

                </div>


                {/* <div className="w-full lg:h-20 mb-12 lg:mb-12 flex lg:grid grid-cols-3">
                    <div className=""></div>
                    <div className=" hidden lg:flex items-center text-2xl  mx-auto cursor-pointer hover:text-amber-400">
                        <div onClick={() => headerLinkClick(3)} className="text-custom2  relative bottom-0  hover:font-bold">&#10229;</div> <div className="font-18 hover:text-amber-400">Results{" "}</div>
                        <div onClick={() => headerLinkClick(5)} className="text-custom2  relative bottom-0  hover:font-bold">&#10230;</div>
                    </div>
                    <div className=""></div>

                </div> */}
            </div>


            <div className="w-screen hidden h-20 lg:flex lg:mb-10 items-end fixed bottom-0 z-10">

                <div className="hidden lg:flex items-center text-2xl  ml-auto mr-14 cursor-pointer ">
                    <div onClick={() => headerLinkClick(3)} className="text-custom2 relative bottom-0 hover:text-amber-400  hover:font-bold">&#10229;</div>
                    <div onClick={() => headerLinkClick(5)} className="text-custom2 relative bottom-0 hover:text-amber-400 hover:font-bold">&#10230;</div>
                </div>

            </div>
        </div>
    )
}