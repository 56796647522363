import { useState } from "react"
// import { page3Panels, page3Panels2 } from "./constants"

export const Page3Panels = ({ theme, pageI, contentData }) => {

    const [hoverPanel, setHoverPanel] = useState(null);
    const page3Panels = [
        {
            title: contentData.strategy_section_1_title,
            subtitle: contentData.strategy_section_1_subtitle,
            content: contentData.strategy_section_1_content,
        },
        {
            title: contentData.strategy_section_2_title,
            subtitle: contentData.strategy_section_2_subtitle,
            content: contentData.strategy_section_2_content,
        },
        {
            title: contentData.strategy_section_3_title,
            subtitle: contentData.strategy_section_3_subtitle,
            content: contentData.strategy_section_3_content,
        },
    ]
    const page3Panels2 = [
        {
            title: contentData.strategy_section_4_title,
            subtitle: contentData.strategy_section_4_subtitle,
            content: contentData.strategy_section_4_content,
        },
        {
            title: contentData.strategy_section_5_title,
            subtitle: contentData.strategy_section_5_subtitle,
            content: contentData.strategy_section_5_content,
        },
        {
            title: contentData.strategy_section_6_title,
            subtitle: contentData.strategy_section_6_subtitle,
            content: contentData.strategy_section_6_content,
        },
    ]

    if (theme === 'dark') {
        return (<div
            style={{ width: "50vw" }}
            className={
                "grid grid-cols-1  h-full ml-auto  " +
                (pageI >= 1 ? " grid-rows-3" : "grid-rows-3")
            }
        >
            {(pageI == 0 ? (<>
                {page3Panels.map((x, i) => hoverPanel == i ? (
                    <div
                        className="px-8   bg-black border border-r-0  border-white flex bg-opacity-80"
                        key={i}
                    >
                        <div className="m-auto  text-custom1">{x.content}</div>
                    </div>
                ) : (<div key={i} onMouseOver={() => setHoverPanel(i)} className="bg-opacity-10 bg-black flex flex-col items-center justify-center border border-r-0  border-white">
                    <div
                        className=" font-18   flex items-center justify-center font-semibold "
                    >
                        {x.title}
                    </div>
                    <div className="mx-10 mt-2">
                        {x.subtitle}
                    </div>


                </div>)


                )} </>) : (<>


                    {page3Panels2.map((x, i) => <div
                        className="px-8   bg-black border border-r-0 flex-col  border-white flex bg-opacity-80"
                        key={i}
                    >
                        <div
                            className=" font-18 mt-auto  mb-2 flex items-center justify-center font-semibold "
                        >
                            {x.title}
                        </div>
                        <div className="mx-auto mb-auto  text-custom1">{x.content}</div>
                    </div>)}

                </>))}
        </div>)


    } else if (theme === 'monument') {
        return (<div
            style={{ width: "50vw" }}
            className={
                " mr-auto h-full  grid grid-cols-1 " +
                (pageI >= 1 ? " grid-rows-3" : "grid-rows-3")
            }
        >
            {(pageI >= 1 ? page3Panels2 : page3Panels).map((x, i) =>
                <div className="flex flex-col px-8  justify-center" key={i}>
                    <div className="font-18 font-semibold mb-2">{x.title}
                    </div>
                    <div className="font-18 mb-3">{x.subtitle}</div>
                    <div className="text-custom1">{x.content}</div>
                </div>
            )}
        </div>)
    } else if (theme === 'cherry') {
        return (<div
            style={{ width: "50vw" }}
            className={
                " mr-auto h-full grid grid-cols-1 test1 " +
                (pageI >= 1 ? " grid-rows-3" : "grid-rows-3 ")
            }
        >
            {(pageI >= 1 ? page3Panels2 : page3Panels).map((x, i) =>
                <div key={i} className={"grid grid-cols-4  hover:bg-white hover:bg-opacity-70 "}>
                    <div className="flex col-span-1 h-full">
                        <div className="flex flex-col my-auto items-center mx-3">
                            <div className="font-18 mb-2 font-semibold ">{x.title}</div>

                        </div>

                    </div>
                    <div className="mx-8 col-span-3 flex flex-col">
                        <div className="font-18 mt-auto mb-2 mr-auto text-left ">{x.subtitle}</div>
                        <div className="text-custom1 mb-auto mr-auto">{x.content}</div>

                    </div>
                </div>
            )}
        </div>)
    }
}