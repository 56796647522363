import { getBg, getBgAttachment, getBgSize } from "./constants"
import { NavBarLeft } from "./navbarleft"

export const WhatWeDo = ({ theme, showSubtext, contentData, globalSw, headerLinkClick }) => {

    return (
        <div
            id="page2"
            style={{ backgroundImage: getBg(2, theme, globalSw), backgroundRepeat: 'no-repeat', backgroundSize: getBgSize(2, theme, globalSw), backgroundAttachment: getBgAttachment(2, theme, globalSw), backgroundPositionY: '-4px' }}
            className=" flex-col z-10  flex  h-auto lg:h-screen w-screen   py-20 lg:py-0"
        >
            <div className="mb-8 flex flex-col max-w-screen-2xl lg:my-auto px-8  h-full">
                <div className={" h-full flex flex-col max-w-2xl mx-auto  lg:mt-0 " + ((theme == 'cherry' && globalSw > 1024) ? 'mr-custom-2 max-w-lg' : '')}>
                    <div className="center-text-6xl text-3xl lg:text-5xl xl:text-5xl mb-4">
                        {contentData.what_we_do_title}
                    </div>
                    {showSubtext ?
                        <div className="flex flex-col  text-custom1 lg:font-18 slidein ">

                            <div className=" mb-8">
                                {contentData.what_we_do_1}
                            </div>


                            <div className=" grid  grid-cols-1 lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-8">
                                <div className="flex flex-col">
                                    <div className=" font-semibold">{contentData.what_we_do_2} </div>
                                    <div className="">
                                        {contentData.what_we_do_3}
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold">{contentData.what_we_do_4} </div>
                                    <div className="">
                                        {contentData.what_we_do_5}
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold">
                                        {contentData.what_we_do_6}
                                    </div>
                                    <div className="">{contentData.what_we_do_7}</div>
                                </div>
                            </div>



                        </div> :
                        <div className=" opacity-0 flex flex-col  text-custom1 lg:font-18  ">

                            <div className=" mb-8">
                                With a deep understanding of the intricate workings of
                                government and a wealth of experience navigating complex
                                policy landscapes, we are dedicated to helping our clients
                                achieve their goals and shape meaningful change.
                            </div>


                            <div className=" grid  grid-cols-1 lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-8">
                                <div className="flex flex-col">
                                    <div className=" font-semibold">Elite Talent</div>
                                    <div className="">
                                        Bipartisan team of D.C. veterans led by former Senate
                                        Majority Leader Tom Daschle
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold">Campaign Mindset</div>
                                    <div className="">
                                        Relentless focus on achieving your goals
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold">
                                        We Live Above the Shop
                                    </div>
                                    <div className="">Your priorities are ours</div>
                                </div>
                            </div>



                        </div>
                    }
                </div>
                {/* <div className="w-full lg:h-20 mb-12 lg:mb-12 flex lg:grid grid-cols-3">
                    <div className=""></div>
                    <div className=" hidden lg:flex items-center text-2xl   mx-auto cursor-pointer hover:text-amber-400">
                        <div onClick={() => headerLinkClick(0)} className="text-custom2 relative bottom-0   hover:font-bold">&#10229;</div> <div className="hover:text-amber-400 font-18">What We Do{" "}</div>
                        <div onClick={() => headerLinkClick(2)} className="text-custom2 relative bottom-0 hover:font-bold">&#10230;</div>
                    </div>
                    <div className=""></div>

                </div> */}




            </div>

            <div className="w-screen hidden h-20 lg:flex lg:mb-10 items-end fixed bottom-0 z-10">

                <div className="hidden lg:flex items-center text-2xl  ml-auto mr-14 cursor-pointer ">
                    <div onClick={() => headerLinkClick(0)} className="text-custom2 relative bottom-0   hover:text-amber-400">&#10229;</div>
                    <div onClick={() => headerLinkClick(2)} className="text-custom2 relative bottom-0 hover:text-amber-400">&#10230;</div>
                </div>

            </div>
        </div>
    )
}